var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('section', {
    staticClass: "login-section"
  }, [_c('div', {
    staticClass: "login-blk"
  }, [_c('div', {
    staticClass: "screen-lft"
  }, [_c('div', {
    staticClass: "logo"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/login.jpg"),
      "alt": ""
    }
  })])])]), _c('div', {
    staticClass: "screen-rgt"
  }, [_c('div', {
    staticClass: "screen-outer"
  }, [_vm.success ? _c('div', {
    staticClass: "forgot-blk-success"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-center my-5"
  }, [_c('span', {
    staticClass: "page-heading"
  }, [_vm._v("確認メールを送信しました。")])]), _c('div', {
    staticClass: "form-header mb-4"
  }, [_vm._v(" メールをお受け取りになりましたら、記載されているURLからパスワードを再設定してください。 ")]), _c('div', {
    staticClass: "message-block pa-3"
  }, [_vm._v(" URLの有効期限は24時間です。メールが届かない場合は、迷惑メールフォルダーなどに振り分けられている場合があります。 ")]), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('div', {
    staticClass: "forget-blk"
  }, [_c('span', {
    staticClass: "forgot-text"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v("ログイン画面")])], 1)])])], 1)], 1) : _c('div', {
    staticClass: "forgot-blk"
  }, [_c('div', {
    staticClass: "screen-title"
  }, [_c('h2', [_vm._v("パスワードの再設定")]), _c('p', [_vm._v(" 登録メールアドレスを入力してください。パスワード再設定用のリンクを送信します。 ")]), _vm.error ? _c('p', {
    staticClass: "text-center red--text pt-4"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "screen-form"
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    staticClass: "input-text",
    attrs: {
      "rules": _vm.rules.email,
      "placeholder": "メールアドレス",
      "name": "email",
      "filled": "",
      "rounded": "",
      "dense": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.email,
      callback: function callback($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('v-btn', {
    staticClass: "text-capitalize btn-submit",
    attrs: {
      "block": "",
      "large": "",
      "color": "primary",
      "disabled": !_vm.isValid,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("送信する")]), _c('div', {
    staticClass: "marin-bottom-field"
  }), _c('div', {
    staticClass: "forget-blk"
  }, [_c('span', {
    staticClass: "forgot-text"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v("ログイン画面")])], 1)])], 1)], 1)])])])])]), _c('div', {
    staticClass: "copyright-blk"
  }, [_c('p', [_vm._v(" ©️ " + _vm._s(new Date().getFullYear()) + " 総合研装株式会社, All Rights Reserved. ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }