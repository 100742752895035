<template>
  <v-app>
    <section class="login-section">
      <div class="login-blk">
        <div class="screen-lft">
          <div class="logo">
            <a href="#">
              <img src="@/assets/images/login.jpg" alt="" />
            </a>
          </div>
        </div>
        <div class="screen-rgt">
          <div class="screen-outer">
            <div class="forgot-blk-success" v-if="success">
              <v-row align="center" justify="center">
                <v-col cols="12">
                  <div class="text-center my-5">
                    <span class="page-heading">確認メールを送信しました。</span>
                  </div>
                  <div class="form-header mb-4">
                    メールをお受け取りになりましたら、記載されているURLからパスワードを再設定してください。
                  </div>
                  <div class="message-block pa-3">
                    URLの有効期限は24時間です。メールが届かない場合は、迷惑メールフォルダーなどに振り分けられている場合があります。
                  </div>
                  <div class="marin-bottom-field"></div>

                  <div class="forget-blk">
                    <span class="forgot-text">
                      <router-link :to="{ name: 'Login' }"
                        >ログイン画面</router-link
                      ></span
                    >
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="forgot-blk" v-else>
              <div class="screen-title">
                <h2>パスワードの再設定</h2>
                <p>
                  登録メールアドレスを入力してください。パスワード再設定用のリンクを送信します。
                </p>
                <p class="text-center red--text pt-4" v-if="error">
                  {{ error }}
                </p>
              </div>
              <div class="screen-form">
                <v-form v-model="isValid">
                  <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    placeholder="メールアドレス"
                    name="email"
                    filled
                    rounded
                    dense
                    class="input-text"
                    hide-details="auto"
                  ></v-text-field>

                  <div class="marin-bottom-field"></div>

                  <v-btn
                    block
                    class="text-capitalize btn-submit"
                    large
                    color="primary"
                    :disabled="!isValid"
                    @click="submit"
                    :loading="isLoading"
                    >送信する</v-btn
                  >

                  <div class="marin-bottom-field"></div>

                  <div class="forget-blk">
                    <span class="forgot-text">
                      <router-link :to="{ name: 'Login' }"
                        >ログイン画面</router-link
                      ></span
                    >
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="copyright-blk">
      <p>
        ©️ {{ new Date().getFullYear() }} 総合研装株式会社, All Rights Reserved.
      </p>
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'ForgotPassword',
  props: {
    success: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: null,
      isValid: true,
      isLoading: false,
      error: null,
      rules: {
        required: v => !!v || 'Required.',
        email: [
          v => !!v || 'メールアドレスを入力してください。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'メールアドレスの形式は正しくありません。正しく入力してください。'
        ]
      }
    }
  },

  methods: {
    submit() {
      this.isLoading = true
      let email = this.email
      this.$store
        .dispatch('AUTH_FORGOT', { email })
        .then(
          response => {
            this.isLoading = false
            if (response.status >= 200 && response.status <= 299) {
              this.email = null
              this.error = ''
              this.$router.push({
                query: { success: true }
              })
            }
          },
          error => {
            throw error
          }
        )
        .catch(error => {
          this.isLoading = false
          if (error.response.data.email) {
            this.error =
              'このメールアドレスは登録されていません。アカウント情報をご確認ください。'
          }
        })
    }
  }
}
</script>

<style scoped src="./Auth.scss" lang="scss" />
